// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import EventOptionsResourceItemModel from '../../model/event-options-resource-item-model';
import EventOptionsResourceItemDTO from '../dto/event-options-resource-item-dto';
import dtoSchema from '../schemas/event-options-resource-item-schema';

export default abstract class EventOptionsResourceItemModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<EventOptionsResourceItemDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.price = data.price ?? undefined;
      this.membership = data.membership ?? undefined;
      this.currency = data.currency ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  * @type {double}
  */
  price?: number;
  /**
  */
  membership?: boolean;
  /**
  */
  currency?: string;

  static toModel(dto: DeepPartial<EventOptionsResourceItemDTO>): EventOptionsResourceItemModel;
  static toModel(dto: DeepPartial<EventOptionsResourceItemDTO> | undefined | null): EventOptionsResourceItemModel | undefined;
  static toModel(dto: DeepPartial<EventOptionsResourceItemDTO> | undefined | null): EventOptionsResourceItemModel | undefined {
    return dto ? new EventOptionsResourceItemModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<EventOptionsResourceItemModel> | EventOptionsResourceItemModel): EventOptionsResourceItemDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      price: unwrapped.price,
      membership: unwrapped.membership,
      currency: unwrapped.currency,
    } as EventOptionsResourceItemDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for EventOptionsResourceItemModel, can not map to EventOptionsResourceItemDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
