// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import CreateEventRegistrationRequestModel from '../../model/create-event-registration-request-model';
import CalculateBasketRequestModel from '../../model/calculate-basket-request-model';
import GetEventByIdRequestModel from '../../model/get-event-by-id-request-model';
import GetEventsListRequestModel from '../../model/get-events-list-request-model';
import BasketModel from '../../model/basket-model';
import EventModel from '../../model/event-model';
import EventListListResultModel from '../../model/event-list-list-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async createEventRegistration(request: CreateEventRegistrationRequestModel, config?: RequestConfig) {
    const requestDTO = CreateEventRegistrationRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-event-registration'] ?? 'Widget/Event/Register';
    const response = await service.post<any>(endpointPath, {
      body: {
        eventId: requestDTO.eventId,
        languageIso2: requestDTO.languageIso2,
        businessUnit: requestDTO.businessUnit,
        customerGroupNumber: requestDTO.customerGroupNumber,
        eventParticipant: requestDTO.eventParticipant,
        basket: requestDTO.basket,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async calculateBasket(request: CalculateBasketRequestModel, config?: RequestConfig) {
    const requestDTO = CalculateBasketRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-calculate-basket'] ?? 'Widget/Event/CalculateBasket';
    const response = await service.post<any>(endpointPath, {
      body: {
        eventId: requestDTO.eventId,
        businessUnit: requestDTO.businessUnit,
        customerGroupNumber: requestDTO.customerGroupNumber,
        basket: requestDTO.basket,
        promoCodes: requestDTO.promoCodes,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(BasketModel.toModel(dto));
  },
  /**  */
  async getEventById(request: GetEventByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetEventByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-event-by-id'] ?? 'Widget/Event/{eventId}';
    endpointPath = endpointPath.replace(/\{eventId\}/g, `${requestDTO.eventId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(EventModel.toModel(dto));
  },
  /**  */
  async getEventsList(request: GetEventsListRequestModel, config?: RequestConfig) {
    const requestDTO = GetEventsListRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-events-list'] ?? 'Widget/Event';
    const response = await service.get<any>(endpointPath, {
      query: {
        searchCriteria: requestDTO.searchCriteria,
        eventTargetAudienceId: requestDTO.eventTargetAudienceId,
        eventCategoryId: requestDTO.eventCategoryId,
        businessUnitNumber: requestDTO.businessUnitNumber,
        dateRangeFrom: requestDTO.dateRangeFrom,
        dateRangeTo: requestDTO.dateRangeTo,
        eventPlace: requestDTO.eventPlace,
        eventGroupingNumber: requestDTO.eventGroupingNumber,
        isEventPublished: requestDTO.isEventPublished,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(EventListListResultModel.toModel(dto));
  },
});
