<template>
  <div v-if="!isLoading && eventInformation" class="widgeteventdetails bookable/soldout">
    <div v-if="eventInformation" class="titlewrapper">
      <h1>{{ eventInformation.eventTitle }}</h1>
      <div class="preis">
        <div v-if="getPrice(true)" class="member">
          {{ getPriceText(true) }}
        </div>
        <div v-if="getPrice(false)" class="nomember">
          {{ getPriceText(false) }}
        </div>
        <div v-if="getPrice(undefined)" class="forall">
          {{ getPriceText(undefined) }}
        </div>
      </div>
    </div>
    <div v-if="eventInformation" class="anmeldung">
      <a class="anmeldelink" :href="innerEventRegistrationUrl()">
        Anmelden <img class="arrow" :src="innerArrowsrc()" alt="Anmelden">
      </a>
    </div>
  </div>
  <p-progress-linear v-else-if="isLoading" indeterminate></p-progress-linear>
</template>
<script lang="ts">
  import GetEventByIdRequestModel from '@/src/services/v2/model/get-event-by-id-request-model';
  import EventModel from '@/src/services/v2/model/event-model';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'WizardEventRegistration',
    layout: 'layout-widget',
    props: {
      eventId: { default: undefined, type: String },
      eventRegistrationUrl: { default: undefined, type: String },
      arrowsrc: { default: undefined, type: String },
    },
    data: () => ({
      isLoading: true,
      title: Vue.$t('kfmv.widget.event-detail.title'),
      eventInformation: undefined as EventModel | undefined,
    }),
    async mounted() {
      this.isLoading = true;
      if (this.innerEventId()) {
        const eventInfoResponse = await this.$service.v2.api.event.getEventById(new GetEventByIdRequestModel({ eventId: this.innerEventId() }));
        if (!eventInfoResponse || !eventInfoResponse.data) {
          Vue.$log.error(`Event with id '${this.innerEventId()}' not found!`);
        } else {
          this.eventInformation = eventInfoResponse.data;
        }
      } else {
        Vue.$log.error('Event id not set!');
      }
      this.isLoading = false;
    },
    methods: {
      innerEventId(): string | undefined {
        if (this._.isSet(this.eventId)) {
          return this.eventId;
        }
        return this.getFromAttributeOrQuery('widget-event-id', 'eventId');
      },
      innerEventRegistrationUrl(): string {
        if (this._.isSet(this.eventRegistrationUrl)) {
          return this.eventRegistrationUrl;
        }
        if (this.$config.values['widget-detail-event-registration-url']) {
          return this.$config.values['widget-detail-event-registration-url'];
        }
        const queries = this.$routerUtils.getQueryParams();
        return queries.eventRegistrationUrl as string;
      },
      innerArrowsrc(): string {
        if (this._.isSet(this.arrowsrc)) {
          return this.arrowsrc;
        }
        if (this.$config.values['widget-detail-event-arrowsrc']) {
          return this.$config.values['widget-detail-event-arrowsrc'];
        }
        const queries = this.$routerUtils.getQueryParams();
        return queries.arrowsrc as string;
      },
      getPrice(isMembership:boolean | undefined) { return isMembership ? this.eventInformation?.memberPriceItem : this.eventInformation?.nonMemberPriceItem; },
      getPriceText(isMembership: boolean | undefined):string | undefined {
        const price = this.getPrice(isMembership);
        if (!price) return undefined;
        return `${price.currency} ${price.price}`;
      },
      getFromAttributeOrQuery<T>(configKey:string, querykey: string): T | undefined {
        configKey = configKey.toLowerCase();
        querykey = querykey.toLowerCase();
        const queries = Vue.$routerUtils.getQueryParams();
        const queriesLowerCase = {} as any;
        Object.keys(queries).forEach((queryKeyName) => {
          queriesLowerCase[queryKeyName.toLowerCase()] = queries[queryKeyName];
        });
        const value = (Vue.$config.values as any)[configKey] ?? Vue.$router.currentRoute.params[querykey] ?? queriesLowerCase[querykey];
        return value;
      },
    },
  });
  </script>
