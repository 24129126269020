// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import BasketItemSchema from './basket-item-schema';
import OnlinePaymentSchema from './online-payment-schema';

const schema = {
  $id: '/basket-schema',
  type: 'object',
  required: ['grossTotal'],
  properties: {
    membershipTemplateId: {
      type: 'string',
      format: 'uuid',
      nullable: true,
    },
    grossTotal: {
      type: 'number',
    },
    currency: {
      type: 'string',
      nullable: true,
    },
    items: {
      type: 'array',
      nullable: true,
      items: { ...BasketItemSchema },
    },
    periodStart: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    periodEnd: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    paymentMethod: {
      enum: [
        1,
        2,
        3,
        6,
        7,
        8,
      ],
    },
    onlinePayment: {
      ...OnlinePaymentSchema,
    },
    promoCodes: {
      type: 'array',
      nullable: true,
      items: {
        type: 'string',
      },
    },
  },
} as IJSONSchema;

export default schema;
