<template>
  <kfmv-wizard-step class="wizard-step-create-event-registration" :custom-proceed-function="customProceed" :errors="validationError">
    <p-request-alert v-model="error"></p-request-alert>
    <p-progress-linear v-if="isLoading" class="my-5" indeterminate></p-progress-linear>
    <div v-else-if="basket" class="eventregistration-payment" :class="classList">
      <p-row v-for="item in basket.items" :key="item.data.itemNumber">
        <p-col xs8>
          {{ $t(item.data.caption) }}
        </p-col>
        <p-col xs4>
          {{ item.data.grossPrice }}
        </p-col>
      </p-row>
      <p-row>
        <p-col xs8>
          <p-text-field v-model="wizardData.basket.promoCodes[0]" :placeholder="$t('kfmv.widget.eventregistration.promocode.watermark')"></p-text-field>
        </p-col>
        <p-col xs4 align-end>
          <p-button @click="recalculateBasket">
            {{ $t('kfmv.widget.eventregistration.promocode.title') }}
          </p-button>
        </p-col>
      </p-row>
      <p-row>
        <p-col xs8>
          {{ $t('kfmv.widget.eventregistration.summary.total') }}
        </p-col>
        <p-col xs4>
          {{ basket.grossTotal }}
        </p-col>
      </p-row>
      <p-row>
        <p-col grow>
          <h2 ref="wizardTitle">
            {{ $t('kfmv.widget.eventregistration.summary.paymentType.title') }}
          </h2>
        </p-col>
      </p-row>
      <p-row>
        <p-col>
          <p v-if="!error && !showPaymentFailedInfo">
            {{ $t("kfmv.widget.eventregistration.payment_form_is_opening") }}
          </p>
          <p-button v-if="!showPaymentWidget" @click="pay()">
            {{ $t('kfmv.widget.eventregistration.payment_pay') }}
          </p-button>
          <kfmv-wallee-widget
            :show="showPaymentWidget"
            :initialize="showPaymentWidget"
            :transaction-id="wizardData.onlinePaymentTransactionId"
            :success-url="buildRedirectUrl('success')"
            :failed-url="buildRedirectUrl('failed')"
          ></kfmv-wallee-widget>
          <p-alert :value="showPaymentFailedInfo" type="error">
            <span v-html="$t('kfmv.widget.eventregistration.payment_failed')"></span>
          </p-alert>
        </p-col>
      </p-row>
    </div>
    <template #step-actions>
      <div class="mt-4 pb-2">
        <p-button
          v-if="!isLoading && !wizardData.onlinePaymentTransactionId"
          class="mr-3"
          secondary
          @click="backFunction"
        >
          <p-icon left>
            arrow_back
          </p-icon>
          {{ $t('core.app.back') }}
        </p-button>
        <p-button
          v-if="!isLoading && !wizardData.onlinePaymentTransactionId"
          @click="pay"
        >
          {{ $t('kfmv.widget.eventregistration.payment_pay') }}
          <p-icon left>
            credit_card
          </p-icon>
        </p-button>
      </div>
    </template>
  </kfmv-wizard-step>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';
  import mixins from '@glittr/frontend-core/src/mixins';
  import validatable from '@glittr/frontend-core/src/mixins/validatable/validatable';
  import pButton from '@glittr/frontend-core/src/components/p-button/p-button.vue';
  import OnlinePaymentContactAddressModel from '@glittr/frontend-core/src/services/v2/model/online-payment-contact-address-model';
  import BasketModel from '@/src/services/v2/model/basket-model';
  import EventModel from '@/src/services/v2/model/event-model';
  import CreateEventRegistrationRequestModel from '@/src/services/v2/model/create-event-registration-request-model';
  import OnlinePaymentOrderItemModel from '@/src/services/v2/model/online-payment-order-item-model';
  import DataRecord from '@glittr/frontend-core/src/core/v2/data/data-record';
  import BasketItemModel from '@/src/services/v2/model/basket-item-model';
  import OnlinePaymentModel from '@/src/services/v2/model/online-payment-model';
  import CreateTransactionRequestModel from '@/src/services/v2/model/create-transaction-request-model';
  import GetEventByIdRequestModel from '@/src/services/v2/model/get-event-by-id-request-model';
  import CalculateBasketRequestModel from '@/src/services/v2/model/calculate-basket-request-model';

  const paymentStateQuery = 'paymentstate';
  type PaymentState = 'success' | 'failed';
  export default mixins(validatable).extend({
    components: { pButton },
    name: 'WizardStepEventRegistrationontactData',
    props: {
      value: { type: Object, default: undefined },
      proceedFunction: { type: Function as PropType<(()=>void)>, default: () => {} },
      clearProgressFunction: { type: Function as PropType<(()=>Promise<void>)>, default: () => {} },
      saveFunction: { type: Function as PropType<(()=>void)>, default: () => {} },
      backFunction: { type: Function as PropType<(()=>void)>, default: () => {} },
    },
    data: () => ({
      isLoading: false,
      error: undefined as any,
      validationError: undefined as string | undefined,
      eventInformation: new EventModel(),
      showPaymentWidget: false,
      showPaymentFailedInfo: false,
      basket: undefined as Omit<BasketModel, 'items'> & { items: DataRecord<BasketItemModel>[]; } | undefined,
    }),

    computed: {

      classList(): Record<string, boolean> {
        return {
          'input-error': this._.isSet(this.validationError),
        };
      },
      wizardData: {
        get(): CreateEventRegistrationRequestModel {
          return this.value ?? {};
        },
        set(value: CreateEventRegistrationRequestModel) {
          this.$emit('input', value);
        },
      },
      shouldCreateTransaction: {
        get(): boolean {
          return this.$sessionStorage.get<boolean>('create-transaction') ?? false;
        },
        set(value: boolean | undefined): void {
          if (value === undefined) {
            this.$sessionStorage.remove('create-transaction');
            return;
          }
          this.$sessionStorage.set('create-transaction', value);
        },
      },
    },
    async beforeMount() {
      this.isLoading = true;
      const eventInfoResponse = await this.$service.v2.api.event.getEventById(new GetEventByIdRequestModel({ eventId: this.wizardData.eventId }));
      this.eventInformation = eventInfoResponse.data;
      this.wizardData.basket = this.wizardData.basket ?? new BasketModel();
      this.wizardData.basket.promoCodes = this.wizardData.basket?.promoCodes ?? [];
      await this.recalculateBasket();

      // Depending how this step was opened it has to be handle differently
      const paymentState = this.$routerUtils.getQueryParamsFromUrl(window?.location?.href)[paymentStateQuery] as PaymentState | undefined;
      if (paymentState === 'success') {
        // Opened via: Redirect from wallee widget and payment was successful
        // Action: Go to confirmation step
        const successRedirektConfigKey = 'widget-event-registration-success-url';
        const queries = Vue.$routerUtils.getQueryParams();
        const successRedirect = Vue.$config.values[successRedirektConfigKey] ?? queries?.successUrl;
        if (!successRedirect) {
          throw new Error(`No success page redirect configured, set "${successRedirektConfigKey}"`);
        }
        Vue.$routerUtils.pushRouteOrUrl(successRedirect);
        return;
      }
      if (paymentState === 'failed') {
        // Opened via: Redirect from wallee widget and payment failed
        // Action: Display that payment failed
        // Continuation: User can click back or pay button
        this.wizardData.basket!.onlinePayment = undefined;
        this.saveFunction!();
        this.$routerUtils.updateQueryParams([], { preserve: false });
        this.showPaymentFailedInfo = true;
        return;
      }
      if (!this.wizardData.basket?.onlinePayment?.transactionId && !this.shouldCreateTransaction) {
        // Opened via: Either page was refreshed while being on an earlier step and previously failed payment
        //             or user used breadcrum and previously failed payment
        // Action: Go back to summary page (only show wallee widget when user expects it)
        this.backFunction!();
        return;
      }
      this.isLoading = false;
    },
    methods: {
      async recalculateBasket() {
        const eventPrice = this.eventInformation.getPrice(this.wizardData.customerGroupNumber);
        const eventOrderItem = new BasketItemModel({
          itemNumber: undefined as any, // ??
          itemPriceNumber: undefined as any, // ??
          quantity: 1,
          currency: 'CHF',
          unitPrice: eventPrice,
          grossPrice: eventPrice,
          itemPriceType: undefined,
          vatRate: undefined,
        });
        const resp = new CalculateBasketRequestModel();
        resp.eventId = this.wizardData.eventId;
        resp.businessUnit = this.wizardData.businessUnit;
        resp.customerGroupNumber = this.wizardData.customerGroupNumber;
        resp.basket = new BasketModel({
          items: [eventOrderItem as any],
          membershipTemplateId: undefined,
          grossTotal: undefined,
          currency: undefined,
          periodStart: undefined,
          periodEnd: undefined,
          paymentMethod: undefined,
          onlinePayment: undefined,
        });
        // Service layer thinks it's a list response, so unpack it so it can be set as the basket
        const recalculatedBasket = (await this.$service.v2.api.event.calculateBasket(resp)) as unknown as BasketModel;
        recalculatedBasket.items = recalculatedBasket.items?.map((i) => (i as any).data);
        this.wizardData.basket = recalculatedBasket;
      },

      buildRedirectUrl(paymentState: PaymentState): string {
        const queries = this.$routerUtils.getQueryParams();
        queries[paymentStateQuery] = paymentState;
        const queryString = Object.keys(queries).map((key) => `${key}=${encodeURIComponent(queries[key])}`).join('&');
        return `${window.location.pathname}?${queryString}#${this.$appId}`;
      },
      async pay(): Promise<void> {
        if (this.wizardData.basket?.onlinePayment?.transactionId) {
          // Opened via: Page was refreshed while being on this payment step
          // Action: Show wallee widget again
          // Continuation: Wallee widget will redirect to this step
          this.error = undefined;
          this.showPaymentFailedInfo = false;
          this.showPaymentWidget = true;
          return;
        }
        // Opened via: User clicked on next button on summary step
        //             or clicked on pay button on this step after payment failed
        // Action: Create new transaction and show wallee widget
        // Continuation: Wallee widget will redirect to this step
        this.error = undefined;
        this.showPaymentFailedInfo = false;
        this.shouldCreateTransaction = undefined;
        this.wizardData.basket = this.wizardData.basket ?? new BasketModel();
        this.wizardData.basket.onlinePayment = this.wizardData.basket.onlinePayment ?? new OnlinePaymentModel();
        this.wizardData.basket.onlinePayment.transactionId = await this.createTransaction();
        this.wizardData.basket.paymentMethod = 6;
        this.saveFunction!();
        this.showPaymentWidget = true;
      },
      async createTransaction(): Promise<string | undefined> {
        this.isLoading = true;
        let transactionId;
        try {
          const transaction = await this.convertToTransaction();
          const resp = await this.$service.v2.api.onlinePaymentTransaction.createTransaction(transaction);
          transactionId = resp.data;
        } catch (error) {
          this.error = error;
        }
        this.isLoading = false;
        return transactionId;
      },
      async convertToTransaction(): Promise<CreateTransactionRequestModel> {
        const orderItems : OnlinePaymentOrderItemModel[] = [];
        const basketItems = this.wizardData.basket?.items ?? [];
        basketItems.forEach((item) => {
          const orderItem = new OnlinePaymentOrderItemModel();
          orderItem.productId = `${item.itemNumber}`;
          orderItem.name = item.caption;
          orderItem.unitPrice = item.unitPrice;
          orderItem.quantity = item.quantity;
          orderItems.push(orderItem);
        });

        const { address } = this.wizardData.eventParticipant!;
        const billingAddress = new OnlinePaymentContactAddressModel();
        billingAddress.salutation = address?.salutation?.toString();
        billingAddress.firstName = address?.firstName ?? undefined;
        billingAddress.lastName = address?.lastName ?? undefined;
        billingAddress.email = address?.email ?? undefined;
        billingAddress.phoneNumber = address?.phoneNumber ?? undefined;
        billingAddress.mobilePhoneNumber = address?.mobilePhoneNumber ?? undefined;
        billingAddress.dateOfBirth = this.$date(address?.dateOfBirth)?.toISOString();
        billingAddress.companyName = address?.companyName ?? undefined;
        billingAddress.street = `${address?.street ?? ''} ${address?.houseNumber ?? ''}`.trim();
        billingAddress.countryISO = address?.countryIso2 ?? undefined;
        billingAddress.zipCode = address?.postalCode ?? undefined;
        billingAddress.city = address?.city ?? undefined;

        const transaction = new CreateTransactionRequestModel();
        transaction.currency = 'CHF';
        transaction.language = this.wizardData.languageIso2 ?? undefined;
        transaction.billingAddress = billingAddress;
        transaction.shippingAddress = undefined;
        transaction.orderItems = orderItems;

        return transaction;
      },
      validate(): boolean {
        return true;
      },
      customProceed() {
        if (this.validate()) {
          this.proceedFunction!();
        }
      },
      async onEventRegistrationSelect(id: any, membership: any) {
        await this.clearProgressFunction!();
        // this.wizardData.membershipTemplateId = membership.id!;
        // this.wizardData.isGiftMembership = false;
        // this.$set(this.wizardData, 'chosenMembershipTemplate', membership);
      },
      onPayed(data: BasketModel) {
        if (!this.wizardData.basket) {
          this.wizardData.basket = new BasketModel();
        }
        this.wizardData.basket.currency = data.currency;
        this.wizardData.basket.periodStart = data.periodStart;
        this.wizardData.basket.periodEnd = data.periodEnd;
        this.wizardData.basket.paymentMethod = data.paymentMethod;
        this.wizardData.basket.onlinePayment = data.onlinePayment;
        this.proceedFunction!();
      },
    },
  });
</script>
