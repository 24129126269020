<template>
  <kfmv-wizard
    ref="wizard"
    class="widget-event-registration"
    :title="innerTitle()"
    :storagekey="$t('kfmv.widget.eventregistrationwizard.wizard_title')"
    :steps="steps"
    :initialize-data="initializeData"
    :service-function="serviceFunction"
    :model-class="model"
    to-on-complete="/"
  ></kfmv-wizard>
</template>

<script lang="ts">
  import Vue from 'vue';

  import { StepData } from '@/src/components/wizard/kfmv-wizard.vue';
  import CreateEventRegistrationRequestModel from '@/src/services/v2/model/create-event-registration-request-model';

  import EventModel from '@/src/services/v2/model/event-model';
  import PaymentMethodDTO from '@/src/services/v2/_generated/dto/payment-method-dto';
  import ContactModel from '@/src/services/v2/model/contact-model';
  import ContactAddressModel from '@/src/services/v2/model/contact-address-model';
  import GetEventByIdRequestModel from '@/src/services/v2/model/get-event-by-id-request-model';
  import paymentSchema from './4.payment.event-registration.schema';
  import contactDataSchema from './3.contactData.event-registration.schema';
  import priceSelectionOptionsSchema from './2.priceSelectionOptions.event-registration.schema';
  import priceSelectionSchema from './1.priceSelection.event-registration.schema';

  export default Vue.extend({
    name: 'WizardEventRegistration',
    layout: 'layout-widget',
    props: {
      language: { default: 'de', type: String },
      bussinesUnit: { default: undefined, type: Number },
      eventId: { default: undefined, type: String },
      title: { default: '', type: String },
    },
    data: () => ({
      steps: [] as StepData[],
      model: CreateEventRegistrationRequestModel,
      eventInformation: undefined as EventModel | undefined,
    }),
    computed: {
      defaultPaymentMethod() {
        return (6) as PaymentMethodDTO;
      },
    },
    async mounted() {
      this.steps = [
        {
          breadcrumb: 'kfmv.widget.eventregistration.priceSelection_title',
          component: () => import('./1.priceSelection.event-registration.vue'),
          schema: priceSelectionSchema,
          disablePreviousSteps: true,
          // clearProgressAfterSend: false / true,
          // sendData: false / true
          // condition: (wizardData: CreateEventRegistrationRequestModel) => !this._.isSet(wizardData.membershipTemplateId),
        },
        {
          breadcrumb: 'kfmv.widget.eventregistration.priceSelectionOptions_title',
          component: () => import('./2.priceSelectionOptions.event-registration.vue'),
          schema: priceSelectionOptionsSchema,
          // clearProgressAfterSend: false / true,
          // sendData: false / true
          condition: (wizardData: CreateEventRegistrationRequestModel) => false, // This step is not needed anymore
        },
        {
          breadcrumb: 'kfmv.widget.eventregistration.contactData_title',
          component: () => import('./3.contactData.event-registration.vue'),
          schema: contactDataSchema,
          // clearProgressAfterSend: false / true,
          // sendData: false / true
          // condition: (wizardData: CreateEventRegistrationRequestModel) => !this._.isSet(wizardData.membershipTemplateId),
        },
        {
          breadcrumb: 'kfmv.widget.eventregistration.payment_title',
          component: () => import('./4.payment.event-registration.vue'),
          schema: paymentSchema,
          // clearProgressAfterSend: false / true,
          // sendData: false / true
          disablePreviousSteps: (wizardData: CreateEventRegistrationRequestModel) => !!wizardData.basket?.onlinePayment?.transactionId,
        },

      //   {
      //     breadcrumb: 'kfmv.widget.eventregistration.order_confirmation',
      //     component: () => import('./8.membership-order-complete.vue'),
      //     disablePreviousSteps: true,
      //     clearProgressOnMounted: true,
      //   },
      ];
      // await this.model.initialize();
      // const wizard = this.$refs.wizard as WizardDataType;
      // wizard.$once('initialized', async (wizardData: CreateEventRegistrationRequestModel) => {
      // await wizard.clearProgress();
      // const { eventId } = wizardData;
      // if (this._.isSet(eventId)) {
      //   await wizard.clearProgress();
      //   wizardData.eventId = eventId;
      //   const currentQuery = this.$routerUtils.getQueryParams();
      //   delete currentQuery.membershipid;
      //   delete this.$route.query.membershipid;
      //   this.$routerUtils.updateQueryParams(currentQuery, { preserve: false });
      //   wizard.saveWizardData();
      //   wizard.setStep(1);
      // }
      // });
      const eventInfoResponse = await this.$service.v2.api.event.getEventById(new GetEventByIdRequestModel({ eventId: this.getFromAttributeOrQuery<string>('widget-event-id', 'eventId') ?? this.eventId }));
      this.eventInformation = eventInfoResponse.data;
    },
    methods: {
      innerTitle(): string {
        const eventInforationTmp = this.eventInformation ?? new EventModel({});

        if (this._.isSet(this.title)) {
          return this.title.replaceAll('{0}', eventInforationTmp.eventName!);
        }
        if (this.$config.values['widget-registration-title']) {
          return this.$config.values['widget-registration-title'].replaceAll('{0}', eventInforationTmp.eventName!);
        }
        const queries = this.$routerUtils.getQueryParams();
        if (queries.title) {
          return queries.title.replaceAll('{0}', eventInforationTmp.eventName!);
        }
        return Vue.$t('kfmv.widget.eventregistrationwizard.wizard_title').replaceAll('{0}', eventInforationTmp.eventName!);
      },

      getFromAttributeOrQuery<T>(configKey:string, querykey: string): T | undefined {
        configKey = configKey.toLowerCase();
        querykey = querykey.toLowerCase();
        const queries = Vue.$routerUtils.getQueryParams();
        const queriesLowerCase = {} as any;
        Object.keys(queries).forEach((queryKeyName) => {
          queriesLowerCase[queryKeyName.toLowerCase()] = queries[queryKeyName];
        });
        const value = (Vue.$config.values as any)[configKey] ?? Vue.$router.currentRoute.params[querykey] ?? queriesLowerCase[querykey];
        return value;
      },
      async serviceFunction(wizardData: CreateEventRegistrationRequestModel) {
        // if (!wizardData.chosenMembershipTemplate?.hasPartnerAddress || !wizardData?.partnerContact?.hasContactAddressValue) {
        //   (wizardData as any).partnerContact = undefined;
        // }

        // if (!wizardData.isGiftMembership) {
        //   (wizardData as any).memberContact = undefined;
        // }

        // await this.$service.v1.api.widgetMemberships.createMembership(wizardData);
      },
      async initializeData() {
        const initializedModel = new CreateEventRegistrationRequestModel({
          languageIso2: this.getFromAttributeOrQuery<string>('language', 'language') ?? this.language,
          businessUnit: Number.parseInt(this.getFromAttributeOrQuery<string>('widget-registration-bussines-unit', 'businessUnit') ?? `${this.bussinesUnit}`, 10),
          eventId: this.getFromAttributeOrQuery<string>('widget-event-id', 'eventId') ?? this.eventId,
          eventParticipant: new ContactModel({
            address: new ContactAddressModel(),
          }),
          basket: {
            items: [],
            paymentMethod: this.defaultPaymentMethod,
            currency: this.$config.values['kfmv-payment-currency-default'],
            grossTotal: undefined,
          },
        });
        return initializedModel;
      },
    },
  });
</script>
