// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CalculateBasketRequestModel from '../../model/calculate-basket-request-model';
import CalculateBasketRequestDTO from '../dto/calculate-basket-request-dto';
import dtoSchema from '../schemas/calculate-basket-request-schema';
import BasketModel from '../../model/basket-model';

export default abstract class CalculateBasketRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CalculateBasketRequestDTO>) {
    super();
    if (data) {
      this.eventId = data.eventId;
      this.businessUnit = data.businessUnit;
      this.customerGroupNumber = data.customerGroupNumber ?? undefined;
      this.basket = data.basket ? BasketModel.toModel(data.basket) : undefined;
      this.promoCodes = data.promoCodes ?? undefined;
    }
  }
  /**
  * @type {uuid}
  */
  eventId?: string;
  /**
  * @type {int32}
  */
  businessUnit?: number;
  /**
  * @type {int32}
  */
  customerGroupNumber?: number;
  /**
  */
  basket?: BasketModel;
  /**
  */
  promoCodes?: string[];

  static toModel(dto: DeepPartial<CalculateBasketRequestDTO>): CalculateBasketRequestModel;
  static toModel(dto: DeepPartial<CalculateBasketRequestDTO> | undefined | null): CalculateBasketRequestModel | undefined;
  static toModel(dto: DeepPartial<CalculateBasketRequestDTO> | undefined | null): CalculateBasketRequestModel | undefined {
    return dto ? new CalculateBasketRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CalculateBasketRequestModel> | CalculateBasketRequestModel): CalculateBasketRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      eventId: unwrapped.eventId,
      businessUnit: unwrapped.businessUnit,
      customerGroupNumber: unwrapped.customerGroupNumber,
      basket: unwrapped.basket,
      promoCodes: unwrapped.promoCodes,
    } as CalculateBasketRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for CalculateBasketRequestModel, can not map to CalculateBasketRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
