<template>
  <p-container class="widget event search form fill-height">
    <p-row column no-gutters nowrap>
      <p-col shrink>
        <h2>{{ $t('page.event-search-configuration.title') }}</h2>
      </p-col>
      <p-col shrink>
        <p-card>
          <p-row>
            <p-col xs12 md3 lg3>
              <p-select
                v-model="widgetConfig.language"
                :label="$t('page.settings.title.languageSettings')"
                :items="languages"
              ></p-select>
            </p-col>
            <p-col xs12 md2 lg2 xl1>
              <p-number-field
                v-model.number="widgetConfig['kfmv-widget-business-unit']"
                :label="$t('page.event-search-configuration.businessUnitNumber.label')"
                no-steppers
                required
                :disabled="!$auth.isInRole('HostAdmin')"
              ></p-number-field>
            </p-col>
            <p-col xs12 md4 lg5 xl4>
              <p-text-field
                v-model="widgetConfig['kfmv-widget-cms-url']"
                :label="$t('page.event-search-configuration.eventDetailUrl.label')"
              ></p-text-field>
            </p-col>
            <p-col :xs12="!$media.isDesktop" :justify-end="!$media.isDesktop" align-end>
              <p-button @click="openWidget(widgetConfig['kfmv-widget-cms-url'], widgetConfig['kfmv-widget-business-unit'], widgetConfig.language)">
                {{ $t('core.app.ok') }}
              </p-button>
              <p-button :title="$t('widget.config-pages.embed-info.hint')" class="ml-2" secondary @click="toggleWidgetEmbedInfo()">
                <p-icon :color="isWidgetEmbedInfoVisible ? 'base' : undefined">
                  dataset_linked
                </p-icon>
                <p-icon right>
                  {{ isWidgetEmbedInfoVisible ? 'expand_less' : 'expand_more' }}
                </p-icon>
              </p-button>
            </p-col>
          </p-row>
        </p-card>
      </p-col>
      <p-card v-if="isWidgetEmbedInfoVisible" class="ma-0">
        <div v-if="!link">
          {{ $t('widget.config-pages.embed-info.no-link-message') }}
        </div>
        <template v-else>
          <p-col shrink>
            <p-row align-center>
              <p-col shrink>
                <a ref="link" :href="link" target="_blank" rel="noopener noreferrer">{{ link }}</a>
              </p-col>
              <p-col grow>
                <p-row dense>
                  <p-button secondary @click="_.copyToClipboard($refs.link)">
                    <p-icon>content_copy</p-icon>
                  </p-button>
                </p-row>
              </p-col>
            </p-row>
          </p-col>
          <p-divider class="mb-4"></p-divider>
          <p-col>
            <p-row>
              <p-col grow>
                <pre ref="widgetEmbedCode" class="code overflow-auto">{{ widgetEmbedSnippet }}</pre>
              </p-col>
              <p-col shrink :xs12="!$media.isDesktop">
                <p-button secondary @click="_.copyToClipboard($refs.widgetEmbedCode)">
                  <p-icon>content_copy</p-icon>
                </p-button>
              </p-col>
            </p-row>
          </p-col>
        </template>
      </p-card>
      <template v-if="link">
        <p-col>
          <div id="result" class="fill-height fill-width px-5 pt-4">
            <!-- WIDGET RENDERED HERE -->
          </div>
        </p-col>
      </template>
    </p-row>
  </p-container>
</template>
<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    auth: true,
    role: ['Admin', 'HostAdmin'],
    data: () => ({
      isWidgetEmbedInfoVisible: false,
      widgetHost: `${window.location.protocol}//${window.location.host}`,
      widgetConfig: {
        'mount-id': 'widget-event-search-id',
        route: '_widgets/event/search',
        language: 'de',
        'kfmv-widget-business-unit': 11 as number | undefined,
        'kfmv-widget-cms-url': undefined as string | undefined,
      },
      widgetEmbedSnippet: undefined as string | undefined,
      link: undefined as string | undefined,
      host: `${window.location.protocol}//${window.location.host}`,

    }),
    computed: {
      languages(): { id: string, caption: string, description: string }[] {
        return this.$translation.availableAppLanguages.map((lang) => ({
          id: lang.toLowerCase(),
          caption: this.$t(`app.language.${lang.toLowerCase()}`),
          description: lang.toLowerCase(),
        }));
      },
    },
    mounted() {
      this.widgetConfig.language = this.$config.getQueryOrValue('language') ?? this.widgetConfig.language;
      const businessUnit = this.$config.getQueryOrValue('kfmv-widget-business-unit', 'businessUnit') ?? this.widgetConfig['kfmv-widget-business-unit']?.toString();
      this.widgetConfig['kfmv-widget-business-unit'] = businessUnit ? Number.parseInt(businessUnit, 10) : undefined;
      this.widgetConfig['kfmv-widget-cms-url'] = this.$config.getQueryOrValue('kfmv-widget-cms-url', 'cmsUrl') ?? this.widgetConfig['kfmv-widget-cms-url'];
    },
    methods: {
      createWidgetEmbedSnippet(scriptQueries?: Record<string, string>) {
        const widgetConfig = this.widgetConfig as Record<string, any>;
        const widgetAttributes = Object.keys(widgetConfig)
          .filter((key) => widgetConfig[key] !== undefined)
          .map((key) => `data-${key}="${widgetConfig[key]}"`)
          .join('\n ');
        const queries = scriptQueries ? `?${Object.keys(scriptQueries).map((i) => `${i}=${scriptQueries[i]}`).join('&')}` : '';
        return `
          <link href="${this.widgetHost}/css/app.css" rel="stylesheet" />
          <div id="${widgetConfig['mount-id']}"></div>
            <script
              async="async"
              data-router-mode="abstract"
              data-config-path="${this.widgetHost}"
              data-auth-apikey="GL-*****************************"
              src="${this.widgetHost}/js/app.js${queries}"
              ${widgetAttributes}
            ><\/script>`
          .replace(/[ \t]+/g, ' ')
          .replace(/^[ \t]/gm, '')
          .trim();
      },
      /**
      * Gets a query value or if that is not set it will retrieve the route param value and then the config value (in that order).
      * The keys are case insensitive, so if there are keys with the same name but different casing the last one will be used.
      * @param configKey The key of the config value to retrieve
      * @param queryKey The key of the parameter or query value to retrieve, if this is not set the configKey will be used
      */
      getQueryOrValue(configKey: string, queryKey?: string): string | undefined {
        const configKeyLower = configKey.toLowerCase();
        queryKey = queryKey?.toLowerCase() ?? configKeyLower;
        const queries = Vue.$routerUtils.getQueryParams();
        const queriesLowerCase = {} as any;
        Object.keys(queries).forEach((queryKeyName) => {
          queriesLowerCase[queryKeyName.toLowerCase()] = queries[queryKeyName];
        });
        const value = (this.$config.values as any)[configKeyLower]?.toString() ?? Vue.$router.currentRoute.params[queryKey] ?? queriesLowerCase[queryKey];
        return value;
      },
      toggleWidgetEmbedInfo() {
        this.isWidgetEmbedInfoVisible = !this.isWidgetEmbedInfoVisible;
      },
      async openWidget(cmsUrl:string, businessUnit:number, language:string) {
        const queries = { cmsUrl, language, businessUnit: businessUnit?.toString() } as Record<string, string>;
        const queryString = Object.keys(queries)
          .filter((key) => queries[key] !== undefined && queries[key] !== null && queries[key] !== '')
          .map((key) => `${key}=${encodeURIComponent(queries[key])}`)
          .join('&');
        this.link = new URL(`${this.widgetHost}/${this.widgetConfig.route}?${queryString}`, document.baseURI).href;
        this.widgetEmbedSnippet = this.createWidgetEmbedSnippet({ cacheBust: Date.now().toString() });
        this.$nextTick(() => {
          const resultElement = document.getElementById('result') as HTMLElement;
          resultElement.replaceChildren(document.createRange().createContextualFragment(this.widgetEmbedSnippet!));
        });
      },
    },
  });
  </script>
