// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import EventListListResultModel from '../../model/event-list-list-result-model';
import EventListListResultDTO from '../dto/event-list-list-result-dto';
import dtoSchema from '../schemas/event-list-list-result-schema';
import EventListModel from '../../model/event-list-model';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class EventListListResultModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<EventListListResultDTO>) {
    super();
    if (data) {
      this.items = data.items?.map((i) => EventListModel.toModel(i)) ?? [];
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
    }
  }
  /**
  */
  items?: EventListModel[];
  /**
  */
  links?: ResourceLinkModel[];

  static toModel(dto: DeepPartial<EventListListResultDTO>): EventListListResultModel;
  static toModel(dto: DeepPartial<EventListListResultDTO> | undefined | null): EventListListResultModel | undefined;
  static toModel(dto: DeepPartial<EventListListResultDTO> | undefined | null): EventListListResultModel | undefined {
    return dto ? new EventListListResultModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<EventListListResultModel> | EventListListResultModel): EventListListResultDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      items: unwrapped.items,
      links: unwrapped.links,
    } as EventListListResultDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for EventListListResultModel, can not map to EventListListResultDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
