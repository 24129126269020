// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import AddressModel from '../../model/address-model';
import AddressDTO from '../dto/address-dto';
import dtoSchema from '../schemas/address-schema';

export default abstract class AddressModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<AddressDTO>) {
    super();
    if (data) {
      this.street = data.street ?? undefined;
      this.houseNumber = data.houseNumber ?? undefined;
      this.additionToAddress = data.additionToAddress ?? undefined;
      this.poBox = data.poBox ?? undefined;
      this.countryISO = data.countryISO ?? undefined;
      this.zipCode = data.zipCode ?? undefined;
      this.city = data.city ?? undefined;
      this.company = data.company ?? undefined;
    }
  }
  /**
  */
  street?: string;
  /**
  */
  houseNumber?: string;
  /**
  */
  additionToAddress?: string;
  /**
  */
  poBox?: string;
  /**
  */
  countryISO?: string;
  /**
  */
  zipCode?: string;
  /**
  */
  city?: string;
  /**
  */
  company?: string;

  static toModel(dto: DeepPartial<AddressDTO>): AddressModel;
  static toModel(dto: DeepPartial<AddressDTO> | undefined | null): AddressModel | undefined;
  static toModel(dto: DeepPartial<AddressDTO> | undefined | null): AddressModel | undefined {
    return dto ? new AddressModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<AddressModel> | AddressModel): AddressDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      street: unwrapped.street,
      houseNumber: unwrapped.houseNumber,
      additionToAddress: unwrapped.additionToAddress,
      poBox: unwrapped.poBox,
      countryISO: unwrapped.countryISO,
      zipCode: unwrapped.zipCode,
      city: unwrapped.city,
      company: unwrapped.company,
    } as AddressDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for AddressModel, can not map to AddressDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
