// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';
import Int32LookupSchema from './int32-lookup-schema';
import AddressSchema from './address-schema';

const schema = {
  $id: '/event-list-schema',
  type: 'object',
  required: [],
  nullable: true,
  properties: {
    id: {
      type: 'string',
      format: 'uuid',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    eventTitle: {
      type: 'string',
      nullable: true,
    },
    description: {
      type: 'string',
      nullable: true,
    },
    location: {
      type: 'string',
      nullable: true,
    },
    businessUnitNumber: {
      type: 'integer',
      nullable: true,
    },
    categoryNumber: {
      type: 'integer',
      nullable: true,
    },
    eventTypeNumber: {
      type: 'integer',
      nullable: true,
    },
    groupingInfo: {
      ...Int32LookupSchema,
    },
    availabilityStatus: {
      enum: [
        1,
        2,
        3,
        4,
        5,
        6,
      ],
    },
    registrationItemNumber: {
      type: 'integer',
      nullable: true,
    },
    defaultCustomerGroupNumber: {
      type: 'integer',
      nullable: true,
    },
    specification: {
      type: 'string',
      nullable: true,
    },
    content: {
      type: 'string',
      nullable: true,
    },
    websiteUrl: {
      type: 'string',
      nullable: true,
    },
    venueAddress: {
      ...AddressSchema,
    },
    isEventPublished: {
      type: 'boolean',
      nullable: true,
    },
    eventBegin: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    eventEnd: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    registrationBegin: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    registrationEnd: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    isOnlinePaymentEnabled: {
      type: 'boolean',
    },
  },
} as IJSONSchema;

export default schema;
