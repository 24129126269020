import Vue from 'vue';
import EventModelBase from '../_generated/modelBases/event-model-base';

export default class EventModel extends EventModelBase {
  static get MEMBER_GROUP() { return Vue.$config.values['widget-event-customerGroupNumberMember']; }

  static get NON_MEMBER_GROUP() { return Vue.$config.values['widget-event-customerGroupNumberNonMember']; }

  getPrice(group: number | undefined | null): number | undefined {
    if (group === undefined || group === null) {
      return undefined;
    }
    return this.prices?.[group];
  }

  get isFree(): boolean {
    return Object.keys(this.prices ?? {}).length === 0;
  }

  // TODO: This should be moved to the backend in some form
  get customerGroupSettings() {
    return {
      [EventModel.MEMBER_GROUP]: {
        id: EventModel.MEMBER_GROUP,
        caption: Vue.$t('kfmv.widget.eventregistration.priceselectionOptions.memberprice'),
      },
      [EventModel.NON_MEMBER_GROUP]: {
        id: EventModel.NON_MEMBER_GROUP,
        caption: Vue.$t('kfmv.widget.eventregistration.priceselectionOptions.notmemberprice'),
      },
    } as Record<any, { caption: string, id: number }>;
  }

  get memberPriceItem() {
    return this.priceItems.find((item) => item.customerGroupNumber === EventModel.MEMBER_GROUP);
  }

  get nonMemberPriceItem() {
    return this.priceItems.find((item) => item.customerGroupNumber === EventModel.NON_MEMBER_GROUP);
  }

  get priceItems() {
    return Object.entries(this.prices ?? {}).map(([group, price]) => {
      const groupCaption = this.customerGroupSettings[group]?.caption ?? 'UNKNOWN CUSTOMER GROUP';
      const currency = 'CHF';
      return {
        customerGroupNumber: parseInt(group, 10),
        price,
        currency,
        caption: `${groupCaption}: ${price} ${currency}`,
      };
    });
  }
}
