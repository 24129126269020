// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/basket-item-schema',
  type: 'object',
  required: ['itemNumber', 'itemPriceNumber', 'itemPriceType', 'quantity', 'unitPrice', 'grossPrice', 'vatRate'],
  nullable: true,
  properties: {
    itemNumber: {
      type: 'integer',
    },
    itemPriceNumber: {
      type: 'integer',
    },
    itemPriceType: {
      type: 'integer',
    },
    type: {
      type: 'string',
      nullable: true,
    },
    caption: {
      type: 'string',
      nullable: true,
    },
    quantity: {
      type: 'number',
    },
    currency: {
      type: 'string',
      nullable: true,
    },
    unitPrice: {
      type: 'number',
    },
    grossPrice: {
      type: 'number',
    },
    vatRate: {
      type: 'number',
    },
    promotionCode: {
      type: 'string',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
