<template>
  <p-container class="widget event detail form" fill-height>
    <p-row column no-gutters>
      <p-col shrink>
        <h2>{{ $t('page.event-detail-configuration.title') }}</h2>
      </p-col>
      <p-col shrink>
        <p-card>
          <p-row>
            <p-col xs12 sm6 md4 lg2>
              <p-select
                v-model="widgetConfig.language"
                :label="$t('page.settings.title.languageSettings')"
                :items="languages"
              ></p-select>
            </p-col>
            <p-col xs12 sm6 md4 lg3>
              <p-text-field
                v-model="widgetConfig['widget-event-id']"
                required
                :label="$t('page.event-detail-configuration.eventId.label')"
              ></p-text-field>
            </p-col>
            <p-col xs12 sm6 md3 lg2 xl1>
              <p-text-field
                v-model="widgetConfig['widget-detail-event-arrowsrc']"
                :label="$t('page.event-detail-configuration.arrowsrc.label')"
              ></p-text-field>
            </p-col>
            <p-col xs12 sm6 md4 lg3 xl2>
              <p-text-field
                v-model="widgetConfig['widget-detail-event-registration-url']"
                required
                :label="$t('page.event-detail-configuration.registrationUrl.label')"
              ></p-text-field>
            </p-col>
            <p-col :xs12="!$media.isDesktop" :justify-end="!$media.isDesktop" align-end>
              <p-button
                :disabled="!(widgetConfig['widget-event-id'] && widgetConfig['widget-detail-event-registration-url'])"
                @click="openWidget(widgetConfig['widget-event-id'], widgetConfig['widget-detail-event-registration-url'], widgetConfig['widget-detail-event-arrowsrc'], widgetConfig.language)"
              >
                {{ $t('core.app.ok') }}
              </p-button>
              <p-button :title="$t('widget.config-pages.embed-info.hint')" class="ml-2" secondary @click="toggleWidgetEmbedInfo()">
                <p-icon :color="isWidgetEmbedInfoVisible ? 'base' : undefined">
                  dataset_linked
                </p-icon>
                <p-icon right>
                  {{ isWidgetEmbedInfoVisible ? 'expand_less' : 'expand_more' }}
                </p-icon>
              </p-button>
            </p-col>
          </p-row>
        </p-card>
      </p-col>
      <p-card v-if="isWidgetEmbedInfoVisible" class="ma-0">
        <div v-if="!link">
          {{ $t('widget.config-pages.embed-info.no-link-message') }}
        </div>
        <template v-else>
          <p-col shrink>
            <p-row align-center nowrap>
              <p-col shrink>
                <a ref="link" :href="link" target="_blank" rel="noopener noreferrer">{{ link }}</a>
              </p-col>
              <p-col grow>
                <p-row dense>
                  <p-button secondary @click="_.copyToClipboard($refs.link)">
                    <p-icon>content_copy</p-icon>
                  </p-button>
                </p-row>
              </p-col>
            </p-row>
          </p-col>
          <p-divider class="mb-4"></p-divider>
          <p-col>
            <p-row>
              <p-col grow>
                <pre ref="widgetEmbedCode" class="code">{{ widgetEmbedSnippet }}</pre>
              </p-col>
              <p-col shrink>
                <p-button secondary @click="_.copyToClipboard($refs.widgetEmbedCode)">
                  <p-icon>content_copy</p-icon>
                </p-button>
              </p-col>
            </p-row>
          </p-col>
        </template>
      </p-card>
      <template v-if="link">
        <p-col>
          <iframe id="result" title="Widget result" class="fill-height fill-width px-5 pt-4"></iframe>
        </p-col>
      </template>
    </p-row>
  </p-container>
</template>
<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    data: () => ({
      isWidgetEmbedInfoVisible: false,
      widgetConfig: {
        'mount-id': 'widget-event-detail-id',
        route: '_widgets/event/detail',
        language: 'de',
        'widget-event-id': undefined,
        'widget-detail-event-registration-url': undefined,
        'widget-detail-event-arrowsrc': undefined,
      } as Record<string, string | undefined>,
      link: undefined as string | undefined,
      host: `${window.location.protocol}//${window.location.host}`,
    }),
    computed: {
      widgetEmbedSnippet(): string {
        const widgetAttributes = Object.keys(this.widgetConfig)
          .filter((key) => this.widgetConfig[key] !== undefined)
          .map((key) => `data-${key}="${this.widgetConfig[key]}"`)
          .join('\n ');
        return `
          <link href="${this.host}/css/app.css" rel="stylesheet" />
          <div id="${this.widgetConfig['mount-id']}"></div>
            <script
              async="async"
              data-router-mode="abstract"
              data-config-path="${this.host}/"
              data-auth-apikey="GL-*****************************"
              src="${this.host}/js/app.js"
              ${widgetAttributes}
            ><\/script>`
          .replace(/[ \t]+/g, ' ')
          .replace(/^[ \t]/gm, '')
          .trim();
      },
      languages() {
        return this.$translation.availableAppLanguages.map((lang) => ({
          id: lang.toLowerCase(),
          caption: this.$t(`app.language.${lang.toLowerCase()}`),
          description: lang.toLowerCase(),
        }));
      },
    },
    mounted() {
      this.widgetConfig.language = this.$config.getQueryOrValue('language') ?? this.widgetConfig.language;
      this.widgetConfig['widget-event-id'] = this.$config.getQueryOrValue('widget-event-id', 'eventId') ?? this.widgetConfig['widget-event-id']?.toString();
      this.widgetConfig['widget-detail-event-registration-url'] = this.$config.getQueryOrValue('widget-detail-event-registration-url', 'registrationurl') ?? this.widgetConfig['widget-detail-event-registration-url']?.toString();
      this.widgetConfig['widget-detail-event-arrowsrc'] = this.$config.getQueryOrValue('widget-detail-event-arrowsrc', 'arrowsrc') ?? this.widgetConfig['widget-detail-event-arrowsrc']?.toString();
    },
    methods: {
      toggleWidgetEmbedInfo() {
        this.isWidgetEmbedInfoVisible = !this.isWidgetEmbedInfoVisible;
      },
      async openWidget(eventId:string, eventRegistrationUrl:string, arrowsrc:string, language:string) {
        const queries = {
          eventId, eventRegistrationUrl, arrowsrc, language,
        } as Record<string, string>;
        const queryString = Object.keys(queries)
          .filter((key) => queries[key] !== undefined && queries[key] !== null && queries[key] !== '')
          .map((key) => `${key}=${encodeURIComponent(queries[key])}`)
          .join('&');
        this.link = new URL(`/${this.widgetConfig.route}?${queryString}`, document.baseURI).href;
        this.$nextTick(() => {
          const iframe = document.getElementById('result') as HTMLIFrameElement;
          iframe.src = this.link!;
        });
      },
    },
  });
  </script>
