// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import EventOptionsSchema from './event-options-schema';

const schema = {
  $id: '/event-schema',
  type: 'object',
  required: [],
  properties: {
    eventId: {
      type: 'string',
      format: 'uuid',
    },
    eventName: {
      type: 'string',
      nullable: true,
    },
    prices: {
      type: 'number',
      nullable: true,
    },
    options: {
      type: 'array',
      nullable: true,
      items: { ...EventOptionsSchema },
    },
    onlinePaymentEnabled: {
      type: 'boolean',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
