// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CreateEventRegistrationRequestModel from '../../model/create-event-registration-request-model';
import CreateEventRegistrationRequestDTO from '../dto/create-event-registration-request-dto';
import dtoSchema from '../schemas/create-event-registration-request-schema';
import ContactModel from '../../model/contact-model';
import BasketModel from '../../model/basket-model';

export default abstract class CreateEventRegistrationRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CreateEventRegistrationRequestDTO>) {
    super();
    if (data) {
      this.eventId = data.eventId;
      this.languageIso2 = data.languageIso2 ?? undefined;
      this.businessUnit = data.businessUnit;
      this.customerGroupNumber = data.customerGroupNumber ?? undefined;
      this.eventParticipant = ContactModel.toModel(data.eventParticipant);
      this.basket = data.basket ? BasketModel.toModel(data.basket) : undefined;
    }
  }
  /**
  * @type {uuid}
  */
  eventId?: string;
  /**
  */
  languageIso2?: string;
  /**
  * @type {int32}
  */
  businessUnit?: number;
  /**
  * @type {int32}
  */
  customerGroupNumber?: number;
  /**
  */
  eventParticipant?: ContactModel;
  /**
  */
  basket?: BasketModel;

  static toModel(dto: DeepPartial<CreateEventRegistrationRequestDTO>): CreateEventRegistrationRequestModel;
  static toModel(dto: DeepPartial<CreateEventRegistrationRequestDTO> | undefined | null): CreateEventRegistrationRequestModel | undefined;
  static toModel(dto: DeepPartial<CreateEventRegistrationRequestDTO> | undefined | null): CreateEventRegistrationRequestModel | undefined {
    return dto ? new CreateEventRegistrationRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CreateEventRegistrationRequestModel> | CreateEventRegistrationRequestModel): CreateEventRegistrationRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      eventId: unwrapped.eventId,
      languageIso2: unwrapped.languageIso2,
      businessUnit: unwrapped.businessUnit,
      customerGroupNumber: unwrapped.customerGroupNumber,
      eventParticipant: unwrapped.eventParticipant,
      basket: unwrapped.basket,
    } as CreateEventRegistrationRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for CreateEventRegistrationRequestModel, can not map to CreateEventRegistrationRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
