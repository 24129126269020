<template>
  <kfmv-wizard-step class="wizard-step-create-event-registration" :custom-proceed-function="customProceed" :errors="validationError">
    <p-request-alert v-model="error"></p-request-alert>
    <p-progress-linear v-if="isLoading" class="my-5" indeterminate></p-progress-linear>
    <p-row>
      <p-col xs12>
        <p-conditional-wrapper :show="wizardData.eventParticipant.address.schema">
          <p-form :model="wizardData.eventParticipant">
            <p-row align-end class="salutation">
              <p-col xs12>
                <p-select
                  v-model="wizardData.eventParticipant.address.salutation"
                  :items="salutationItems"
                  required
                  :label="$t('core.address.salutation')"
                ></p-select>
              </p-col>
            </p-row>
            <p-row>
              <p-col xs12>
                <p-text-field v-model="wizardData.eventParticipant.address.firstName" name="fname" autocomplete="cc-given-name" :label="$t('core.address.firstName')" required></p-text-field>
              </p-col>
            </p-row>
            <p-row>
              <p-col xs12>
                <p-text-field v-model="wizardData.eventParticipant.address.lastName" name="lname" autocomplete="cc-family-name" :label="$t('core.address.lastName')" required></p-text-field>
              </p-col>
            </p-row>
            <p-row>
              <p-col xs9>
                <p-text-field v-model="wizardData.eventParticipant.address.street" autocomplete="address-line1" :label="$t('core.address.street')" required></p-text-field>
              </p-col>
              <p-col xs3>
                <p-text-field v-model="wizardData.eventParticipant.address.houseNumber" autocomplete="house-number" :label="$t('core.address.houseNumber')"></p-text-field>
              </p-col>
            </p-row>
            <p-row>
              <p-col xs12>
                <p-text-field v-model="wizardData.eventParticipant.address.poBox" autocomplete="po-box" :label="$t('core.address.poBox')"></p-text-field>
              </p-col>
            </p-row>
            <p-row>
              <p-col xs12>
                <p-text-field v-model="wizardData.eventParticipant.address.addressLine1" :label="$t('core.address.addressLine1')" autocomplete="address-line2"></p-text-field>
              </p-col>
            </p-row>
            <p-row>
              <p-col xs3>
                <p-text-field v-model="wizardData.eventParticipant.address.postalCode" autocomplete="postal-code" :label="$t('core.address.postalCode')" required></p-text-field>
              </p-col>
              <p-col xs9>
                <p-text-field v-model="wizardData.eventParticipant.address.city" autocomplete="address-level2" :label="$t('core.address.city')" required></p-text-field>
              </p-col>
            </p-row>
            <p-row>
              <p-col xs12>
                <kfmv-country-lookup v-model="wizardData.eventParticipant.address.countryIso2" autocomplete="country" :label="$t('core.address.country')" required></kfmv-country-lookup>
              </p-col>
            </p-row>
            <p-row>
              <p-col xs12>
                <p-phone-field
                  v-model="wizardData.eventParticipant.address.phoneNumber"
                  autocomplete="tel"
                  :label="$t('core.address.phoneNumber')"
                ></p-phone-field>
              </p-col>
            </p-row>
            <p-row>
              <p-col xs12>
                <p-phone-field
                  v-model="wizardData.eventParticipant.address.mobilePhoneNumber"
                  required
                  autocomplete="mobile"
                  :label="$t('core.address.mobilePhoneNumber')"
                ></p-phone-field>
              </p-col>
            </p-row>
            <p-row>
              <p-col xs12>
                <p-text-field
                  v-model="wizardData.eventParticipant.address.email"
                  autocomplete="email"
                  :label="$t('core.address.email')"
                  required
                ></p-text-field>
              </p-col>
            </p-row>
            <p-row>
              <p-col xs12>
                <p-date-field
                  v-model="wizardData.eventParticipant.address.dateOfBirth"
                  autocomplete="bday"
                  :label="$t('core.address.birthDate')"
                  required
                  :max="maxDate"
                ></p-date-field>
              </p-col>
            </p-row>
          </p-form>
        </p-conditional-wrapper>
      </p-col>
    </p-row>
  </kfmv-wizard-step>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';

  import mixins from '@glittr/frontend-core/src/mixins';
  import validatable from '@glittr/frontend-core/src/mixins/validatable/validatable';
  import CreateEventRegistrationRequestModel from '@/src/services/v2/model/create-event-registration-request-model';

  export default mixins(validatable).extend({
    name: 'WizardStepEventRegistrationContactData',
    props: {
      value: { type: Object, default: undefined },
      proceedFunction: { type: Function as PropType<(()=>void)>, default: () => {} },
      clearProgressFunction: { type: Function as PropType<(()=>Promise<void>)>, default: () => {} },
    },
    data: () => ({
      salutationItems: [{ caption: Vue.$t('core.address.salutationEnum1'), id: 0 },
                        { caption: Vue.$t('core.address.salutationEnum2'), id: 1 },
                        { caption: Vue.$t('core.address.salutationEnum3'), id: 2 }],
      isLoading: false,
      error: undefined as any,
      validationError: undefined as string | undefined,
    }),

    computed: {
      wizardData: {
        get(): CreateEventRegistrationRequestModel {
          return this.value ?? {};
        },
        set(value: CreateEventRegistrationRequestModel) {
          this.$emit('input', value);
        },
      },

      maxDate(): any {
        return this.$date.now().add(-1, 'day').format('YYYY-MM-DD');
      },
    },
    methods: {
      validate(): boolean {
        if (!this._.isSet(this.wizardData.eventParticipant)) {
          this.validationError = this.$t('kfmv.widget.eventregistration.contactdata_error');
          return false;
        }
        return true;
      },
      customProceed() {
        if (this.validate()) {
          this.$sessionStorage.set('create-transaction', true);
          this.proceedFunction!();
        }
      },
    },
  });
</script>
