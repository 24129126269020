import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import Vue from 'vue';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import _ from '@glittr/frontend-core/src/utils';

export default class EventSearchVM extends PageBase {
  get language(): string { return this.getFromAttributeOrQuery('language', 'language') ?? 'de'; }

  get businessUnitNumber(): number | undefined {
    const val = this.getFromAttributeOrQuery('kfmv-widget-business-unit', 'businessUnit');
    if (val === 'undefined') return undefined;
    return _.isSet(val) ? Number.parseInt(val, 10) : undefined;
  }

  get cmsUrl(): string | undefined {
    const val = this.getFromAttributeOrQuery('kfmv-widget-cms-url', 'cmsUrl');
    if (val === 'undefined') return undefined;
    return val ?? undefined;
  }

  get eventsTableColumns() {
    return [
      { field: 'eventTitle', value: Vue.$t('widget.event-search.event-table.header.title') },
      // { field: 'description', value: 'Beschreibung' }, // Same as title
      { field: 'eventBegin', value: Vue.$t('widget.event-search.event-table.header.begin'), format: (v: string) => (v ? Vue.$format.localDate(v) : 'Unbekannt') },
      { field: 'id', value: 'ID' },
    ];
  }

  get eventsTableData() { return this.eventsDataSource.data?.items.map((i) => i.data) ?? []; }

  public eventsDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.event.getEventsList,
  });

  public clearFilter(): void {
    // Can't clear businessUnitNumber as it's fixed to the current business unit
    // this.eventsDataSource.filter.businessUnitNumber = undefined;
    this.eventsDataSource.filter.dateRangeFrom = undefined;
    this.eventsDataSource.filter.dateRangeTo = undefined;
    this.eventsDataSource.filter.eventCategoryId = undefined;
    this.eventsDataSource.filter.eventGroupingNumber = undefined;
    this.eventsDataSource.filter.eventPlace = undefined;
    this.eventsDataSource.filter.eventTargetAudienceId = undefined;
    this.eventsDataSource.filter.isEventPublished = undefined;
    this.eventsDataSource.filter.searchCriteria = undefined;
  }

  public populateData() {
    this.eventsDataSource.select();
  }

  getFromAttributeOrQuery(configKey: keyof typeof Vue.$config.values, querykey: string): string | undefined {
    querykey = querykey.toLowerCase();
    const queries = Vue.$routerUtils.getQueryParams();
    const queriesLowerCase = {} as any;
    Object.keys(queries).forEach((queryKeyName) => {
      queriesLowerCase[queryKeyName.toLowerCase()] = queries[queryKeyName];
    });
    const value = Vue.$config.values[configKey] ?? queriesLowerCase[querykey];
    return value ?? undefined;
  }

  async initialize(): Promise<void> {
    this.eventsDataSource.filter.businessUnitNumber = this.businessUnitNumber;
    if (this.eventsDataSource.filter.businessUnitNumber === undefined) {
      throw new Error('BusinessUnitNumber is not set');
    }
    this.eventsDataSource.filter.dateRangeFrom = Vue.$date.now().format('YYYY-MM-DD');
  }
}
