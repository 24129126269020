<template>
  <kfmv-wizard-step class="wizard-step-create-event-registration" :custom-proceed-function="customProceed" :errors="validationError">
    <p-request-alert v-model="error"></p-request-alert>
    <p-progress-linear v-if="isLoading" class="my-5" indeterminate></p-progress-linear>
    <div v-else class="eventregistration-price-selection" :class="classList">
      <p-row v-if="eventInfo && eventInfo.priceItems && eventInfo.priceItems.length > 0" column>
        <p-radio-field
          v-for="priceItem in eventInfo.priceItems"
          :key="priceItem.customerGroupNumber"
          v-model="wizardData.customerGroupNumber"
          :title="priceItem.caption"
          :true-value="priceGroup"
          name="price"
          :label="priceItem.caption"
        ></p-radio-field>
      </p-row>
      <p-row v-else>
        <p-radio-field
          key="false"
          name="price"
          :true-value="null"
          :value="null"
          :label="$t('widget.event-details.prices.FreeForAll')"
        ></p-radio-field>
      </p-row>
    </div>
  </kfmv-wizard-step>
</template>

<script lang="ts">
  import { PropType } from 'vue';
  import mixins from '@glittr/frontend-core/src/mixins';
  import validatable from '@glittr/frontend-core/src/mixins/validatable/validatable';
  import DataRecord from '@glittr/frontend-core/src/core/v2/data/data-record';
  import CreateEventRegistrationRequestModel from '@/src/services/v2/model/create-event-registration-request-model';
  import EventModel from '@/src/services/v2/model/event-model';
  import GetEventByIdRequestModel from '@/src/services/v2/model/get-event-by-id-request-model';

  export default mixins(validatable).extend({
    name: 'WizardStepEventRegistrationPriceSelection',
    props: {
      value: { type: CreateEventRegistrationRequestModel, default: undefined },
      proceedFunction: { type: Function as PropType<(()=>void)>, default: () => {} },
      clearProgressFunction: { type: Function as PropType<(()=>Promise<void>)>, default: () => {} },
    },
    data: () => ({
      isLoading: false,
      error: undefined as any,
      validationError: undefined as string | undefined,
      optionsList: undefined as DataRecord<number> | undefined,
      eventInfo: undefined as EventModel | undefined,
    }),

    computed: {
      classList(): Record<string, boolean> {
        return {
          'input-error': this._.isSet(this.validationError),
        };
      },
      wizardData: {
        get(): CreateEventRegistrationRequestModel {
          return this.value ?? {} as CreateEventRegistrationRequestModel;
        },
        set(value: CreateEventRegistrationRequestModel) {
          this.$emit('input', value);
        },
      },
    },
    async beforeMount() {
      this.isLoading = true;
      try {
        const eventInfoResponse = await this.$service.v2.api.event.getEventById(new GetEventByIdRequestModel({ eventId: this.wizardData.eventId }));
        this.eventInfo = eventInfoResponse.data;
      } catch (error: any) {
        this.error = error;
        this.$log.error(error);
      }
      this.isLoading = false;
    },
    methods: {
      validate(): boolean {
        if (this.eventInfo?.prices && this.eventInfo.prices?.length > 0 && this._.isEmpty(this.wizardData.customerGroupNumber)) {
          this.validationError = this.$t('kfmv.widget.eventregistration.priceselection_error');
          return false;
        }
        return true;
      },
      customProceed() {
        if (this.validate()) {
          this.proceedFunction!();
        }
      },
    },
  });
</script>
