// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import EventModel from '../../model/event-model';
import EventDTO from '../dto/event-dto';
import dtoSchema from '../schemas/event-schema';
import EventOptionsModel from '../../model/event-options-model';

export default abstract class EventModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<EventDTO>) {
    super();
    if (data) {
      this.eventId = data.eventId;
      this.eventName = data.eventName ?? undefined;
      this.prices = data.prices ?? undefined;
      this.options = data.options?.map((i) => EventOptionsModel.toModel(i)) ?? [];
      this.onlinePaymentEnabled = data.onlinePaymentEnabled ?? undefined;
    }
  }
  /**
  * @type {uuid}
  */
  eventId?: string;
  /**
  */
  eventName?: string;
  /**
  */
  prices?: Record<any, number>;
  /**
  */
  options?: EventOptionsModel[];
  /**
  */
  onlinePaymentEnabled?: boolean;

  static toModel(dto: DeepPartial<EventDTO>): EventModel;
  static toModel(dto: DeepPartial<EventDTO> | undefined | null): EventModel | undefined;
  static toModel(dto: DeepPartial<EventDTO> | undefined | null): EventModel | undefined {
    return dto ? new EventModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<EventModel> | EventModel): EventDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      eventId: unwrapped.eventId,
      eventName: unwrapped.eventName,
      prices: unwrapped.prices,
      options: unwrapped.options,
      onlinePaymentEnabled: unwrapped.onlinePaymentEnabled,
    } as EventDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for EventModel, can not map to EventDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
