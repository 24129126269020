<template>
  <p-container class="widget event registration form" fill-height>
    <p-container>
      <p-row>
        <p-col><h2>{{ $t('page.event-registration-configuration.title') }}</h2></p-col>
      </p-row>
      <p-row>
        <p-col xs3 md2>
          <p-select
            v-model="widgetLanguage"
            :label="$t('page.settings.title.languageSettings')"
            :items="languages"
          ></p-select>
        </p-col>
        <p-col xs3 md2>
          <p-number-field
            v-model.number="widgetBusinessUnit"
            :label="$t('page.event-registration-configuration.businessUnitNumber.label')"
            no-steppers
          ></p-number-field>
        </p-col>
        <p-col xs3 md3>
          <p-text-field
            v-model="widgetEventId"
            :label="$t('page.event-registration-configuration.eventId.label')"
            required
          ></p-text-field>
        </p-col>
        <p-col xs3 md4>
          <p-text-field
            v-model="widgetSuccessUrl"
            label="Success URL"
            required
          ></p-text-field>
        </p-col>
        <p-col xs3 md4>
          <p-text-field
            v-model="widgetTitle"
            :label="$t('page.event-registration-configuration.title.label')"
          ></p-text-field>
        </p-col>

        <p-col xs2 align-end shrink>
          <p-row align-end>
            <p-col align-end shrink>
              <p-button @click="openWidget(widgetEventId, widgetBusinessUnit, widgetLanguage, widgetTitle, widgetSuccessUrl)">
                {{ $t('core.app.ok') }}
              </p-button>
            </p-col>
            <p-col align-end shrink>
              <p-button @click="resetWidget()">
                {{ $t('core.app.cancel') }}
              </p-button>
            </p-col>
          </p-row>
        </p-col>
      </p-row>
      <p-row v-if="link">
        <p-col grow>
          <p ref="paragraph">
            {{ link }}
          </p>
        </p-col>
        <p-col shrink>
          <p-button @click="_.copyToClipboard($refs.paragraph)">
            <p-icon>content_copy</p-icon>
          </p-button>
        </p-col>
        <p-col shrink>
          <a :href="link" target="_blank" rel="noopener noreferrer">
            <p-icon>open_in_new</p-icon>
          </a>
        </p-col>
      </p-row>
    </p-container>

    <p-container v-if="errorMessage.length > 0">
      <p-alert :value="true" type="error">
        {{ errorMessage }}
      </p-alert>
    </p-container>

    <p-container v-if="link">
      <p-row>
        <p-col grow>
          <pre ref="paragraphCode" class="code">&lt;link href=&quot;{{ host }}/css/app.css&quot; rel=&quot;stylesheet&quot; /&gt;
&lt;div id=&quot;kfmv-event-registration&quot;&gt;&lt;/div&gt;
  &lt;script
  async=&quot;async&quot;
  data-mount-id=&quot;kfmv-event-registration&quot;
  data-router-mode=&quot;abstract&quot;
  data-language=&quot;{{ widgetLanguage }}&quot;
  data-route=&quot;_widgets/event/registration&quot;
  data-config-path=&quot;{{ host }}/&quot;
  data-widget-event-id=&quot;{{ widgetEventId }}&quot;
  data-widget-event-registration-success-url=&quot;{{ widgetSuccessUrl }}&quot;
  data-widget-registration-bussines-unit=&quot;{{ widgetBusinessUnit }}&quot;
  data-widget-registration-title=&quot;{{ widgetTitle }}&quot;
  data-auth-apikey=&quot;GL-*****************************&quot;
  src=&quot;{{ host }}/js/app.js&quot;&gt;
&lt;/script&gt;</pre>
        </p-col>
        <p-col shrink>
          <p-button @click="_.copyToClipboard($refs.paragraphCode)">
            <p-icon>content_copy</p-icon>
          </p-button>
        </p-col>
      </p-row>
    </p-container>
    <p-divider class="my-4"></p-divider>
    <iframe id="result" title="Widget result" class="fill-height fill-width px-5"></iframe>
  </p-container>
</template>
<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    auth: true,
    role: ['Admin', 'HostAdmin'],
    data() {
      return {
        languages: [
          // TODO: Use app available languages (see event-search for an example)
          { id: 'de', caption: 'Deutsch' },
          { id: 'fr', caption: 'Französisch' },
          { id: 'it', caption: 'Italienisch' },

        ],
        link: undefined as string | undefined,
        host: `${window.location.protocol}//${window.location.host}`,
        errorMessage: '',
        widgetEventId: 'E0B4BBEC-CAC1-40B2-B51F-1ABA3884E82F' as string | undefined,
        widgetLanguage: 'de' as string | undefined,
        widgetTitle: 'Event Registration' as string | undefined,
        widgetSuccessUrl: 'https://www.gl-it.ch' as string | undefined,
        widgetBusinessUnit: 11 as number | undefined,
      };
    },
    methods: {
      resetWidget() {
        this.link = undefined;
        this.widgetEventId = undefined;
      },
      async openWidget(eventId:string, businessUnit: number, language: string, title:string, successUrl:string) {
        if (!eventId) {
          this.errorMessage = 'Please enter an event Id';
          return;
        }
        const queries = {
          eventId, businessUnit: businessUnit?.toString(), language, title, successUrl,
        } as Record<string, string>;
        const queryString = Object.keys(queries)
          .filter((key) => queries[key] !== undefined && queries[key] !== null && queries[key] !== '')
          .map((key) => `${key}=${encodeURIComponent(queries[key])}`)
          .join('&');
        this.link = new URL(`/_widgets/event/registration?${queryString}`, document.baseURI).href;
        this.$nextTick(() => {
          const iframe = document.getElementById('result') as HTMLIFrameElement;
          iframe.src = this.link!;
        });
      },
    },
  });
  </script>
