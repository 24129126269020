// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import EventListModel from '../../model/event-list-model';
import EventListDTO from '../dto/event-list-dto';
import dtoSchema from '../schemas/event-list-schema';
import ResourceLinkModel from '../../model/resource-link-model';
import Int32LookupModel from '../../model/int32-lookup-model';
import AddressModel from '../../model/address-model';

export default abstract class EventListModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<EventListDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.eventTitle = data.eventTitle ?? undefined;
      this.description = data.description ?? undefined;
      this.location = data.location ?? undefined;
      this.businessUnitNumber = data.businessUnitNumber ?? undefined;
      this.categoryNumber = data.categoryNumber ?? undefined;
      this.eventTypeNumber = data.eventTypeNumber ?? undefined;
      this.groupingInfo = data.groupingInfo ? Int32LookupModel.toModel(data.groupingInfo) : undefined;
      this.availabilityStatus = data.availabilityStatus;
      this.registrationItemNumber = data.registrationItemNumber ?? undefined;
      this.defaultCustomerGroupNumber = data.defaultCustomerGroupNumber ?? undefined;
      this.specification = data.specification ?? undefined;
      this.content = data.content ?? undefined;
      this.websiteUrl = data.websiteUrl ?? undefined;
      this.venueAddress = data.venueAddress ? AddressModel.toModel(data.venueAddress) : undefined;
      this.isEventPublished = data.isEventPublished ?? undefined;
      this.eventBegin = data.eventBegin ?? undefined;
      this.eventEnd = data.eventEnd ?? undefined;
      this.registrationBegin = data.registrationBegin ?? undefined;
      this.registrationEnd = data.registrationEnd ?? undefined;
      this.isOnlinePaymentEnabled = data.isOnlinePaymentEnabled;
    }
  }
  /**
  * @type {uuid}
  */
  id?: string;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  */
  eventTitle?: string;
  /**
  */
  description?: string;
  /**
  */
  location?: string;
  /**
  * @type {int32}
  */
  businessUnitNumber?: number;
  /**
  * @type {int32}
  */
  categoryNumber?: number;
  /**
  * @type {int32}
  */
  eventTypeNumber?: number;
  /**
  */
  groupingInfo?: Int32LookupModel;
  /**
  * @type {int32}
  */
  availabilityStatus?: 1 | 2 | 3 | 4 | 5 | 6; // EventAvailabilityStatusDTO
  /**
  * @type {int32}
  */
  registrationItemNumber?: number;
  /**
  * @type {int32}
  */
  defaultCustomerGroupNumber?: number;
  /**
  */
  specification?: string;
  /**
  */
  content?: string;
  /**
  */
  websiteUrl?: string;
  /**
  */
  venueAddress?: AddressModel;
  /**
  */
  isEventPublished?: boolean;
  /**
  * @type {date-time}
  */
  eventBegin?: string;
  /**
  * @type {date-time}
  */
  eventEnd?: string;
  /**
  * @type {date-time}
  */
  registrationBegin?: string;
  /**
  * @type {date-time}
  */
  registrationEnd?: string;
  /**
  */
  isOnlinePaymentEnabled?: boolean;

  static toModel(dto: DeepPartial<EventListDTO>): EventListModel;
  static toModel(dto: DeepPartial<EventListDTO> | undefined | null): EventListModel | undefined;
  static toModel(dto: DeepPartial<EventListDTO> | undefined | null): EventListModel | undefined {
    return dto ? new EventListModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<EventListModel> | EventListModel): EventListDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      eventTitle: unwrapped.eventTitle,
      description: unwrapped.description,
      location: unwrapped.location,
      businessUnitNumber: unwrapped.businessUnitNumber,
      categoryNumber: unwrapped.categoryNumber,
      eventTypeNumber: unwrapped.eventTypeNumber,
      groupingInfo: unwrapped.groupingInfo,
      availabilityStatus: unwrapped.availabilityStatus,
      registrationItemNumber: unwrapped.registrationItemNumber,
      defaultCustomerGroupNumber: unwrapped.defaultCustomerGroupNumber,
      specification: unwrapped.specification,
      content: unwrapped.content,
      websiteUrl: unwrapped.websiteUrl,
      venueAddress: unwrapped.venueAddress,
      isEventPublished: unwrapped.isEventPublished,
      eventBegin: unwrapped.eventBegin,
      eventEnd: unwrapped.eventEnd,
      registrationBegin: unwrapped.registrationBegin,
      registrationEnd: unwrapped.registrationEnd,
      isOnlinePaymentEnabled: unwrapped.isOnlinePaymentEnabled,
    } as EventListDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for EventListModel, can not map to EventListDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
